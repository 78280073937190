@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sk-chase {
  width: 100%;
  height: 100%;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #3f51b5;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }


  .sk-chase-white {
    width: 100%;
    height: 100%;
      position: relative;
      animation: sk-chase 2.5s infinite linear both;
    }

  .sk-chase-dot-white {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot-white:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #FFFFFF;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot-white:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot-white:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot-white:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot-white:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot-white:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot-white:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot-white:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot-white:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot-white:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot-white:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot-white:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot-white:nth-child(6):before { animation-delay: -0.6s; }

  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }


.noDecorationsLink {
  text-decoration: inherit;
  color:inherit;
}