body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.menuItemActive {
  color: #fff;
  text-decoration: none;
}
.menuItemActive > div {
  background-color: #3f51b5;
}

.menuItemActive > div > div > svg {
  fill: #fff;
}
